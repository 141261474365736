<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" />

    <div class="center w-100">
      <app-breadcrumb-navbar
        class="flex-grow-1"
      />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.fullName }}
            </p>
            <!--            <span class="user-status">{{ userData.role }}</span>-->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/icons/blank-user.svg')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="toggleLocale"
        >
          <b-img
            :src="otherLocale.img"
            height="14px"
            width="22px"
            :alt="otherLocale.locale"
          />
          <span class="ml-50 text-body">{{ otherLocale.name }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('logout') }}</span>
        </b-dropdown-item>

      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BImg,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import AppBreadcrumbNavbar from '@core/layouts/components/AppBreadcrumbNavbar.vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BImg,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    AppBreadcrumbNavbar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      /* eslint-disable global-require */
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
    otherLocale() {
      return this.locales.find(l => l.locale !== this.$i18n.locale)
    },
    locales() {
      return [
        {
          locale: 'de',
          img: require('@/assets/images/flags/de.png'),
          name: this.$t('german'),
        },
        {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: this.$t('english'),
        },
      ]
    },
  },
  methods: {
    logout() {
      this.$store.commit('organizations/setOrganizations', [])
      this.$store.commit('suborganizations/setSuborganizations', [])
      this.$store.commit('clusters/setClusters', [])
      this.$store.commit('localGroups/setLocalGroups', [])
      this.$store.commit('emmas/setEmmas', [])
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    toggleLocale() {
      if (this.currentLocale.locale === 'de') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'de'
      }
    },
  },
}
</script>
