export default [
  {
    header: 'dashboards',
  },
  {
    title: 'organisation',
    route: 'organizationDashboard',
    icon: 'GlobeIcon',
    level: 'all',
    breadcrumb: [
      {
        text: 'Dashboard Organisation',
        active: true,
      },
    ],
  },
  {
    title: 'county',
    route: 'suborganizationDashboard',
    icon: 'FlagIcon',
    level: 'all',
    breadcrumb: [
      {
        text: 'Dashboard Land',
        active: true,
      },
    ],
  },
  {
    title: 'house',
    route: 'clusterDashboard',
    icon: 'HomeIcon',
    level: 'all',
    breadcrumb: [
      {
        text: 'Dashboard Haus',
        active: true,
      },
    ],
  },
  {
    title: 'flat',
    route: 'localGroupDashboard',
    icon: 'SmileIcon',
    level: 'all',
    breadcrumb: [
      {
        text: 'Dashboard Wohnung',
        active: true,
      },
    ],
  },
  {
    title: 'emma',
    route: 'emmaDashboard',
    icon: 'HeartIcon',
    level: 'all',
    breadcrumb: [
      {
        text: 'Dashboard Emma',
        active: true,
      },
    ],
  },

  {
    header: 'admin',
    level: 'admin',
  },
  {
    title: 'testing',
    route: 'api',
    level: 'admin',
    icon: 'AlertOctagonIcon',
    breadcrumb: [
      {
        text: 'Testing',
        active: true,
      },
    ],
  },
  {
    title: 'users',
    route: 'userAdmin',
    level: 'admin',
    icon: 'UserCheckIcon',
    breadcrumb: [
      {
        text: 'users',
        active: true,
      },
    ],
  },
  {
    title: 'organizations',
    route: 'organizationAdmin',
    level: 'admin',
    icon: 'UserCheckIcon',
    breadcrumb: [
      {
        text: 'Organization Management',
        active: true,
      },
    ],
  },
]
