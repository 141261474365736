<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-0"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top ml-1">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            <div v-if="currentPageTitle === undefined">
              {{ $route.meta.pageTitle }}
            </div>
            <div v-else>
              {{ currentPageTitle }}
            </div>
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item
                v-if="$store.state.breadcrumbs.breadcrumbs.length !== 0"
                to="/"
                @click="$store.commit('breadcrumbs/clearBreadcrumbs')"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $store.state.breadcrumbs.breadcrumbs"
                :key="item.text"
                :active="item.active"
                :to="item.to"
                @click="addBreadcrumb(item)"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  computed: {
    currentPageTitle() {
      if (this.$store.state.app.currentPageTitle !== '') {
        return this.$store.state.app.currentPageTitle
      }
      return 'undefined'
    },
  },
  methods: {
    addBreadcrumb(item) {
      if (!item.active) {
        this.$store.commit('breadcrumbs/clearBreadcrumbsAfterCurrent', item.text)
      }
    },
  },
}
</script>
